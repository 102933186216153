.container {
  background-color: #f8f8f8;
  height: 100%;
}
.container .content {
  background-color: #f8f8f8;
  padding-bottom: 1rem;
}
.container .head_right {
  color: #ffffff;
  font-size: 14px;
}
.user_msg {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  font-family: PingFang SC;
}
.user_msg .label_input {
  margin: 0 0 0 0.16rem;
  padding: 0.28rem 0;
  display: flex;
  align-items: center;
}
.user_msg .label_input .label {
  color: #333333;
  font-size: 0.3rem;
  text-align: left;
  font-weight: 500;
}
.user_msg .label_input .input {
  margin-left: 0.32rem;
  font-size: 0.3rem;
}
.user_msg .label_input .input input {
  padding-right: 1rem;
  width: 100%;
  color: #999999;
  border: none;
}
.user_msg .xiala {
  margin: 0 0.2rem;
  padding: 0.26rem 0;
  border-bottom: 0.5px solid #cccccc;
  display: flex;
  justify-content: space-between;
}
.user_msg .xiala .label {
  font-size: 0.3rem;
  color: #333333;
  font-weight: 500;
  text-align: left;
}
.user_msg .xiala .label1 {
  font-size: 0.3rem;
  color: #333333;
  font-weight: 500;
  text-align: left;
}
.user_msg .xiala_content {
  margin: 0 0.2rem;
  font-size: 0.3rem;
}
.user_msg .xiala_content .option_select {
  border-bottom: 0.5px solid #cccccc;
  padding: 0.2rem 0;
  display: flex;
  font-size: 0.3rem;
  justify-content: center;
}
.user_msg .xiala_content .option_select:last-child {
  border-bottom: none;
}
.user_msg .label_input1 {
  margin: 0 0 0 0.16rem;
  padding: 0.28rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #cccccc;
}
.user_msg .label_input1 .label {
  color: #333333;
  font-size: 0.3rem;
  text-align: left;
  font-weight: 500;
}
.user_msg .label_input1 .input {
  margin-left: 0.22rem;
  font-size: 0.3rem;
}
.user_msg .label_input1 .input input {
  text-align: right;
  padding-right: 0.2rem;
  width: 100%;
  color: #999999;
  border: none;
}
.user_msg:last-child {
  padding-bottom: 0.4rem;
}
.bottom {
  position: fixed;
  left: 0;
  bottom: 0.2rem;
  padding: 0;
}
.bottom .button {
  margin: 0.14rem 0.3rem 0 0.28rem;
  width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.12rem;
  font-weight: 500;
}
